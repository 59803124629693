import React, { useState } from 'react'
import api from '../components/api'
import nav from '../utils/Navigation'
import { createModal } from '../mobx/ModalInterface'
import { FaEyeSlash, FaRegEye } from 'react-icons/fa6'

function ScreenValidateResetPassword(props) {
    const [password ,setPassword] = useState(null)
    const [vPassword, setVPassword] = useState(null)
    const [isPasswordVisible , setIsPasswordVisible] = useState(false)
    const [isVPasswordVisible , setIsVPasswordVisible] = useState(false)


    async function onSubmit(e){
        e.preventDefault();
        const r_token = localStorage.getItem('r_token')

        if(password != vPassword){
            createModal(props.setModalInterface, "Error" , "Password doesnt match!")
            return
        }

        if(!r_token){
            createModal(props.setModalInterface, "Error" , "Token not found plesae enter using the link")
            return
        }
        try {
            const response = await api.post('/auth/validate_reset_password' , {
                password: password,
                v_password: vPassword,
                r_token: r_token
            })
            if(response.data.status){
                createModal(props.setModalInterface , "Success" , "Your password has been reset!")
                nav.changeScreen(props.setPageDetails, 'login')
            } else {
                createModal(props.setModalInterface, "Error" , response.data.msg)
            }
            console.log(response);
            
        } catch (e) {
            console.log(e);
            
        }
    }

    const togglePasswordVisibility = () => {
       
      };

  return (
    <div className="login-screen" style={{ display: props.visible ? "flex" : "none" }}>
        <div className="login-container">
            <form className="login-form"  onSubmit={onSubmit}>
                <h2 className="login-title">Reset Password</h2>
                <div className='password-form' > 
                        <input 
                            type={isPasswordVisible ? "text" : 'password'}
                            className="login-input"
                            placeholder="New Password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            minLength={6}
                            maxLength={18}
                        />
                        {isPasswordVisible ? <FaEyeSlash onClick={() =>  setIsPasswordVisible(prevState => !prevState)} style={{marginBottom: '1rem' , padding: '10px'}} size={25} /> : <FaRegEye onClick={() => setIsPasswordVisible(prevState => !prevState)} style={{marginBottom: '1rem' , padding: '10px'}} size={25} />}
                    </div>
                
                <div className='password-form'>
                    <input 
                        type={isVPasswordVisible ? "text" : 'password'}
                        className="login-input"
                        placeholder="Validate Password"
                        name="v_password"
                        onChange={(e) => setVPassword(e.target.value)}
                        value={vPassword}
                        required
                        minLength={6}
                        maxLength={18}
                    />
                    {isVPasswordVisible ? <FaEyeSlash onClick={() =>  setIsVPasswordVisible(prevState => !prevState)} style={{marginBottom: '1rem' , padding: '10px'}} size={25} /> : <FaRegEye onClick={() => setIsVPasswordVisible(prevState => !prevState)} style={{marginBottom: '1rem' , padding: '10px'}} size={25} />}

                </div>
                

                <button className="login-button" type="submit">Reset Password</button>
                <label className="register-text">Back to login <a className="register-link" onClick={() => nav.changeScreen(props.setPageDetails, "login")}>Click Here!</a></label>

            </form>
        </div>
    </div>
  )
}

export default ScreenValidateResetPassword