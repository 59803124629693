import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './GameTemplateCard.css'; // Import your CSS file

const GameTemplateCard = ({ templateName, images , setSelectedTemplate , selectedTemplate , handleFileChange , setListOfFiles}) => {

    function isSelectedTempalte(){
        if(templateName == selectedTemplate){
            return true
        } else {
            return false
        }
    }

    function handlePreview(){
      
    }

    function handleSelect(){
        if(templateName == selectedTemplate){
            setSelectedTemplate(null)
        } else {
            setListOfFiles(images)
            setSelectedTemplate(templateName)
        }
    }
  return (
    <div className="game-template-card">
      <div className="card-top">
        <h2 className="template-name">{templateName}</h2>
        <Carousel showArrows={true} showThumbs={false} infiniteLoop={false} swipeable={true} showIndicators={false}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={`${process.env.REACT_APP_SERVER_URL}/${image}`} alt={`Template ${index}`} className="image-carousel" />
            </div>
          ))}
        </Carousel>
      </div>
      { isSelectedTempalte() ? 
      <div className='card-game-logo' >
        <label>Game Logo</label>
        <input name='logo.png'  accept="image/*" onChange={handleFileChange} type='file'></input>
      </div> : null}
      
      <div className="card-bottom">
        <button className="btn select-btn" style={{backgroundColor: isSelectedTempalte() ? "red" : "#0056b3"}} onClick={() => handleSelect()}>{isSelectedTempalte() ? "Remove This Template" : "Select This Template"}</button>
        <button className="btn preview-btn">Preview Template</button>
      </div>
    </div>
  );
};

export default GameTemplateCard;
