import React , {useEffect, useState} from 'react'
import SettingsManager from '../../components/Settings/SettingsManager'
import './Phases.css'
import LotterySettings from '../../components/Settings/LotterySettings'
import api from '../../components/api'

function SettingsPhase({changePhase , settings , setSettings , handleFileChange , gameName , setGameName , currentGame , ...props}) {
    const [allowTos, setAllowTos] = useState(false)
    const [quizUrl , setQuizUrl] = useState(null)

    // async function getQuizUrl(){
    //   if(currentGame != 'quiz'){
    //     return
    //   }
    //   try {
    //     const response = await api.post('/games/get_quiz_questions_template', {
    //       user_id: props.userDetails.id
    //     })
    //     console.log(response);
        
    //     setQuizUrl(response.data.url)
    //   } catch (e) {
    //     console.log(e);
        
    //   }
    // }
    

    useEffect(() => {

    },[])

  return (
    <div className='phases-container'>
        <h2>Game Settings</h2>
        
        <div>
            <input placeholder='Game Name' value={gameName} onChange={(event) => setGameName(event.target.value)} ></input>
        </div>
        <br />
        <LotterySettings settings={settings} setSettings={setSettings} currentGame={currentGame} gameId={props.appDetails.game_id} />
        {quizUrl ? <a href={quizUrl} target='_blank'>Click here to modify questions</a> : null}
        <div className='tos-setting'>
            <label>Terms & Conditons</label>
            <input type='checkbox' checked={allowTos} onChange={() => setAllowTos(perv => !perv)}></input>
            
            {allowTos ? <input type='file' onChange={handleFileChange} accept="application/pdf"  name={"toc.pdf"}></input> : null}
            
        </div>
        <button onClick={() => changePhase(2)}>Next</button>
    </div>
  )
}

export default SettingsPhase