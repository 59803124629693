import React, { useState, useEffect } from 'react';
import './LotterySettings.css'; // Importing the CSS file

const LotterySettings = ({ settings, setSettings , currentGame , gameId}) => {
  const [showRegistration, setShowRegistration] = useState(true);
  const [showParameters, setShowParameters] = useState(true);

  // Load settings from localStorage on initial load
  useEffect(() => {
    const storedSettings = localStorage.getItem(`${currentGame}_settings`);
    if (storedSettings && !gameId) {
      setSettings(JSON.parse(storedSettings));
    }
  }, [setSettings]);

  // Save settings to localStorage whenever it is updated
  const saveSettingsToLocalStorage = (updatedSettings) => {
    if(gameId){
      return
    }
    localStorage.setItem(`${currentGame}_settings`, JSON.stringify(updatedSettings));
  };

  // Update registration sub-values
  const updateRegistration = (field, value) => {
    setSettings(prev => {
      const updatedSubValues = {
        ...prev.registration.subValues,
        [field]: value,
      };
      let updatedMainIdMethod = [...updatedSubValues.mainIdMethod];
      updatedMainIdMethod = value
        ? [field, ...updatedMainIdMethod.filter(item => item !== field)]
        : updatedMainIdMethod.filter(item => item !== field);

      const updatedSettings = {
        ...prev,
        registration: {
          ...prev.registration,
          subValues: {
            ...updatedSubValues,
            mainIdMethod: updatedMainIdMethod,
          },
        },
      };

      saveSettingsToLocalStorage(updatedSettings); // Save to localStorage
      return updatedSettings;
    });
  };

  // Handle input changes for options
  const handleInputChange = (id, key, updatedValue) => {
    setSettings(prev => {
      const updatedOptions = prev.parameters.subValues.options.map(option => {
        if (option.id === id) {
          return { ...option, [key]: updatedValue };
        }
        return option;
      });

      const updatedSettings = {
        ...prev,
        parameters: {
          ...prev.parameters,
          subValues: {
            ...prev.parameters.subValues,
            options: updatedOptions,
          },
        },
      };

      saveSettingsToLocalStorage(updatedSettings); // Save to localStorage
      return updatedSettings;
    });
  };

  const renderInputFields = (item) => {
    return Object.entries(item).map(([key, value]) => {
      if (key === 'id' || key === 'name') return null; // Ignore the 'id' field
      return (
        <div key={key} className="input-group">
          <label>
            {key.charAt(0).toUpperCase() + key.slice(1)}: {/* Capitalize the first letter */}
            {typeof value === 'boolean' ? (
              <input
                type="checkbox"
                checked={value}
                onChange={(e) => {
                  const updatedValue = e.target.checked;
                  handleInputChange(item.id, key, updatedValue); // Handle checkbox change
                }}
              />
            ) : (
              <input
                type={typeof value === 'number' ? 'number' : 'text'}
                value={value}
                onChange={(e) => {
                  const updatedValue = typeof value === 'number' ? Number(e.target.value) : e.target.value;
                  handleInputChange(item.id, key, updatedValue); // Handle input change
                }}
                className="text-input" // Add class for styling
              />
            )}
          </label>
        </div>
      );
    });
  };

  // Function to render game settings based on available fields
  const renderGameSetting = (item, key) => {
    if (item === 'options') {
      return null; // Skip rendering for 'options'
    }

    const value = settings.parameters.subValues[item];
    const type = typeof value;

    const handleChange = (e) => {
      let newValue;
      // Determine the type of the input and set the new value accordingly
      if (type === 'boolean') {
        newValue = e.target.checked; // For checkboxes
      } else if (type === 'number') {
        newValue = Number(e.target.value); // Convert to number for numeric input
      } else {
        newValue = e.target.value; // Default for text input
      }

      // Update the settings state with the new value
      setSettings(prev => {
        const updatedSettings = {
          ...prev,
          parameters: {
            ...prev.parameters,
            subValues: {
              ...prev.parameters.subValues,
              [item]: newValue, // Update the specific setting
            },
          },
        };

        saveSettingsToLocalStorage(updatedSettings); // Save to localStorage
        return updatedSettings;
      });
    };

    return (
      <div key={key} className="option-card">
        <label>
          {item.charAt(0).toUpperCase() + item.slice(1)}: {/* Capitalize the first letter */}
          {type === 'boolean' ? (
            <input
              type="checkbox"
              checked={value}
              onChange={handleChange}
            />
          ) : (
            <input
              type={type === 'number' ? 'number' : 'text'}
              value={value}
              onChange={handleChange}
            />
          )}
        </label>
      </div>
    );
  };

  // Update the Main ID Method dropdown list
  const handleMainIdMethodChange = e => {
    const selectedMethod = e.target.value;
    setSettings(prev => {
      const updatedMainIdMethod = [
        selectedMethod,
        ...prev.registration.subValues.mainIdMethod.filter(item => item !== selectedMethod),
      ];

      const updatedSettings = {
        ...prev,
        registration: {
          ...prev.registration,
          subValues: {
            ...prev.registration.subValues,
            mainIdMethod: updatedMainIdMethod,
          },
        },
      };

      saveSettingsToLocalStorage(updatedSettings); // Save to localStorage
      return updatedSettings;
    });
  };

  return (
    <div className="lottery-settings">
      <h2>Game Settings</h2>

      {/* Registration Section */}
      <div className="section">
        <h3 onClick={() => setShowRegistration(!showRegistration)} className="section-header">
          {settings.registration.name}
          <span className="toggle-arrow">{showRegistration ? '▲' : '▼'}</span> {/* Toggle arrow */}
        </h3>
        {showRegistration && (
          <div className="options-container">
            {Object.keys(settings.registration.subValues).map(key => (
              key !== 'mainIdMethod' && (
                <div key={key} className="checkbox-container">
                  <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                  <input
                    type="checkbox"
                    checked={settings.registration.subValues[key]}
                    onChange={e => updateRegistration(key, e.target.checked)}
                  />
                </div>
              )
            ))}
            {/* Main ID Method Priority Selector */}
            <div className="main-id-method">
              <h4>Main ID Method (Priority)</h4>
              <select value={settings.registration.subValues.mainIdMethod[0] || ''} onChange={handleMainIdMethodChange}>
                {settings.registration.subValues.mainIdMethod
                  .filter((method) => method !== 'companyName') // Filter out 'companyName'
                  .map((method, index) => (
                    <option key={index} value={method}>
                      {method}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}
      </div>

      {/* Game Settings Section */}
      <div className='section'>
        <h3 onClick={() => setShowParameters(!showParameters)} className="section-header">
          {settings.parameters.name}
          <span className="toggle-arrow">{showParameters ? '▲' : '▼'}</span> {/* Toggle arrow */}
        </h3>
        {showParameters && (
          <div className="options-container">
            {settings.parameters.subValues.options ? settings.parameters.subValues.options.map((item) => (
              <div key={item.id} className="option-card">
                <h4>{item.name}</h4>
                {renderInputFields(item)}
              </div>
            )) : null }
            {Object.keys(settings.parameters.subValues).map((item, key) => {
              return(
              <div key={key}>
                {renderGameSetting(item, key)}
              </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LotterySettings;
