import React, { useState } from 'react'
import nav from '../utils/Navigation'
import api from '../components/api'
import { createModal } from '../mobx/ModalInterface';

function ScreenResetPassword(props) {
    const [username ,setUsername] = useState(null)

    async function onSubmit(e){
        e.preventDefault();
        try {
            const response = await api.post('auth/reset_password' , {
                reset_credentials: username
            })
            if(response.data.status){
                createModal(props.setModalInterface , "Success" , "A link has been sent to your email address")
            } else {
                createModal(props.setModalInterface, "Error" , response.data.msg)
            }
            console.log(response);
            
        } catch (e) {
            console.log(e);
            
        }
    }

  return (
    <div className="login-screen" style={{ display: props.visible ? "flex" : "none" }}>
            <div className="login-container">
                <form className="login-form"  onSubmit={onSubmit}>
                    <h2 className="login-title">Reset Password</h2>
                    <input 
                        type="text"
                        className="login-input"
                        placeholder="Username"
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />

                    <button className="login-button" type="submit">Reset Password</button>
                    <label className="register-text">Back to login <a className="register-link" onClick={() => nav.changeScreen(props.setPageDetails, "login")}>Click Here!</a></label>

                </form>
            </div>
        </div>
  )
}

export default ScreenResetPassword