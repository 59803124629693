import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import './GameTable.css';
import nav from '../../utils/Navigation';

const ActiveGamesTable = ({ games, onGameInfo , ...props}) => {
  // State for the search term
  const [searchTerm, setSearchTerm] = useState('');

  // Define the columns for the DataTable
  const columns = [
    {
      name: 'Game ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Game Name',
      selector: (row) => row.game_name,
      sortable: true,
    },
    {
      name: 'Game Type',
      selector: (row) => row.game_type,
      sortable: true,
    },
    {
      name: 'Paid Version',
      selector: (row) => (row.paid ? 'Yes' : 'No'),
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: (row) => new Date(row.creation_datetime).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Expiration Date',
      selector: (row) => new Date(row.expiration_datetime).toLocaleDateString(),
      sortable: true,
    },
    
    {
      name: 'Actions',
      cell: (row) => (
        <button className="info-button" onClick={() => onGameInfo(row.id , row.settings, row.game_type)}>
          View Info
        </button>
      ),
      ignoreRowClick: true,
      
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => !row.active,  // If the game is inactive
      style: {
        backgroundColor: 'red',
        color: 'white',  // Adjust text color for readability
      },
    },
  ];

  // Filter games based on the search term
  const filteredGames = games.filter(
    (game) =>
      (game.game_name && game.game_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      game.id.toString().includes(searchTerm)
  );

  function onGameInfo(id , settings , gameName){
    props.setAppDetails(prev => ({
        ...prev,
        game_id: id ? id : null,
        settings: settings ? settings : null,
        currentGame: gameName
    }))
    console.log("running");
    nav.changeScreen(props.setPageDetails , "gamePhase")
}

  return (
    <div className="table-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by Game ID or Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <DataTable
        
        columns={columns}
        data={filteredGames}
        pagination
        highlightOnHover
        responsive
        striped
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
};

export default ActiveGamesTable;
