import React, { useEffect , useState} from 'react'
import './Screens.css'
import GameCard from '../components/GameCard/GameCard'
import api from '../components/api'
import gameSettings from '../const/Settings'
import { CiSettings } from "react-icons/ci";
import nav from '../utils/Navigation'
import { CiLogout } from "react-icons/ci";
import Cookies from "js-cookie"
import SearchComponent from '../components/SearchComponent/SearchComponent'
import ActiveGamesTable from '../components/GameTable/GameTable'

function ScreenGameSelect(props) {
  const [userGames, setUserGames] = useState([])


  async function getUserGames(){
    try {
      const res = await api.post('/get_user_games', {
        user_id: props.userDetails.id
      })
      if(res.data.status){

        setUserGames(res.data.userGames)
      } 
 
    } catch (e) {
      console.log(e);
    }
  }

  function handleLogout(){ 
    props.setUserDetails(prev => ({
      ...prev,
      id: null,
      username: null,
      email: null,
      companyName: null,
      ref: null
    }))
    nav.changeScreen(props.setPageDetails , 'login')
    Cookies.remove('access-token', {path: '/'})
  }

  function onSettingsClick(){
    nav.changeScreen(props.setPageDetails, "settings")
  }

  useEffect(() => {
    getUserGames()
  },[props.userDetails , props.pageDetails])
  return (
    <div className='game-select-container'  style={{display: props.visible ? "flex" : "none"}}>
      <div className='side-menu'>
        <CiLogout size={60} className='settings-icon' onClick={() => handleLogout()}/>
        <CiSettings className='settings-icon' size={60} onClick={() => onSettingsClick()} />
      </div>

      <div className='game-container-child'>
        <h3 style={{textAlign: 'center'}}>New Game</h3>
        <SearchComponent {...props} data={['scratch' , 'catch' , 'quiz' , 'match3' , 'flying' , 'ninja' , 'slot_machine' , 'wheel_of_fortune' , 'car_racing']} />   
      </div>


      <div className='game-container-child-2'>
        <h3 style={{textAlign: 'center'}}>My Games</h3>
        <ActiveGamesTable {...props} games={userGames} />
      </div>
      
      
        
    </div>
  )
}

export default ScreenGameSelect



