const gameSettings = {
    scratch: {
        isLottery: true,
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters: {
            name: "Win Ratio",
            value: true,
            subValues: {
                options:[
                  {
                     name: "Grand prize!",
                     id:1,
                     probability:1,
                     useRandomImage:false,
                     numberOfSameImages:4
                  },
                  {
                     name:"Mid prize!",
                     id:2,
                     probability:5,
                     useRandomImage: true,
                     numberOfSameImages:3
                  },
                  {
                     name:"Small prize!",
                     id:3,
                     probability: 20,
                     useRandomImage: true,
                     numberOfSameImages: 2
                  }
               ],
               loseProbability:74
            },
            notes: {

            },
            type: 'number'
        },
    },
    catch: {
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters: {
            name: "Game Settings",
            value: true,
            subValues: {
                fallSpeedMultiplier: 1,
                timeToPlay: 60,
                goodPoints: 50,
                badPoints: -50,
                maxPlayTimes: 0,
                goodItemPercentage: 58,
                goodItems: 2,
                badItems: 4,
                goodPopImages: 2,
                badPopImages: 1,
                randomizeGoodPopImages: false,
                randomizeBadPopImages: true
 
            },
            notes: {
                fallSpeedMultiplier: "fallspeednote",
                timeToPlay: "timetoplaynote",
                goodItems: "goodnote"
            },
            type: 'number'
        },
       
    },
    quiz: {
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters: {
            name: "Categories",
            value: false,
            subValues: {
                useCategories: false,
                useDifficulty: false,
                difficulty1Percent: 50,
                difficulty2Percent: 30,
                difficulty3Percent: 20,
                questionsPerRound: 10,
                categoryAmount: 4,

            },
            notes: {

            },
            type: "checkbox"
        }
    },
    match3: {
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters: {
            name: "Game Settings",
            subValues: {
                tile1_score: 10,
                tile2_score: 20,
                tile3_score: 30,
                tile4_score: 40,
                tile5_score: 50,
                tile6_score: 60,
                start_time: 40,
                time_to_add_on_tiles6: 5
            },
            notes: {

            },
            type: 'number'
        }
    },
    flying: {
        isLottery: true,
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters: {
            name: "Game Settings",
            value: false,
            subValues: {
                options: [
                  {
                    probability: 65,
                    score: 10,
                    specialItem: false,
                    isGood: true,
                    speedMultiplier: 0.0,
                    speedModifierDuration: 0.0,
                    bonusHP: 0,
                    bonusTime: 0.0
                  },
                  {
                    probability: 15,
                    score: 30,
                    specialItem: false,
                    isGood: true,
                    speedMultiplier: 0.5,
                    speedModifierDuration: 4.0,
                    bonusHP: 0,
                    bonusTime: 2
                  },
                  {
                    probability: 5,
                    score: 70,
                    specialItem: true,
                    isGood: true,
                    speedMultiplier: 0.0,
                    speedModifierDuration: 0.0,
                    bonusHP: 1,
                    bonusTime: 0.0
                  },
                  {
                    probability: 15,
                    score: 0,
                    specialItem: false,
                    isGood: false,
                    speedMultiplier: 0.0,
                    speedModifierDuration: 0.0,
                    bonusHP: 0,
                    bonusTime: 0.0
                  }
                ],
                backgroundSpeed: 0.0,
                itemsSpeed: 800.0,
                initialHealthPoints: 3,
                maxHealthPoints: 5,
                loseHealthPointOnMissGoodItem: false,
                itemSpeedUpInterval: 5.0,
                itemSpeedUpAmount: 45.0,
                maxItemSpeed: 1400.0,
                initialItemSpawnIntervalSeconds: 1.5,
                minItemSpawnIntervalSeconds: 0.8,
                itemSpawIntervalDecreaseSeconds: 0.023,
                doubleSpawnPercent: 30,
                playTime: 60.0
              }
        }
        
        
    
      

    },
    ninja: {
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
                
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters: {
            value: false,
            name: "Game Settings",
            subValues: {
                "loseHPOnMiss": false,
                "item1Score": 2,
                "item1FrequencyPercent": 30,
                "item2Score": 30,
                "item2FrequencyPercent": 15,
                "item3Score": 1,
                "item3FrequencyPercent": 12,
                "item4Score": 1,
                "item4FrequencyPercent": 12,
                "item5Score": 1,
                "item5FrequencyPercent": 12,
                "itemBadScore": 0,
                "itemBadFrequencyPercent": 19,
                "initialDelayBetweenItemsMs": 1300,
                "douleSpawnPercent": 33
            },
            notes: {

            },
            type: "checkbox"
        }

    },
    slot_machine: {
        isLottery: true,
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters:{
            name: "Game Paramaters",
            subValues:{
               options:[
                  {
                     name:"Main prize",
                     id:1,
                     probability:5
                  },
                  {
                     name:"Small prize",
                     id:2,
                     probability:25
                  }
               ],
               loseProbability: 70,
               rollsPerGame:3,
               wheelRollCountMin: 1,
               wheelRollCountMax: 2,
               providedImages:12
            }
         }

    },
    wheel_of_fortune: {
        isLottery: true,
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters: {
            name: "Game Paramaters",
            subValues: {
                options:[
                    {
                       name:"-5%",
                       id:0,
                       probability:15,
                       canRollAgain:false,
                       win:true
                    },
                    {
                       name:"No luck",
                       id:1,
                       probability:14,
                       canRollAgain:false,
                       win:false
                    },
                    {
                       name:"-20%",
                       id:2,
                       probability:6,
                       canRollAgain:false,
                       "win":true
                    },
                    {
                       name:"No luck",
                       id:3,
                       probability:14,
                       canRollAgain:false,
                       win:false
                    },
                    {
                       name:"-10%",
                       id:4,
                       probability:10,
                       canRollAgain:false,
                       win:true
                    },
                    {
                       name:"Spin again",
                       id:5,
                       probability:13,
                       canRollAgain:true,
                       win:false
                    },
                    {
                       name:"-5%",
                       id:6,
                       probability:15,
                       canRollAgain:false,
                       win:true
                    },
                    {
                       name:"Spin again",
                       id:7,
                       probability:13,
                       canRollAgain:true,
                       win:false
                    }
                 ]
            },
            notes: {

            },
            type: 'checkbox'
        }
        
    },
    car_racing: {
        isLottery: true,
        registration: {
            name: "Registration",
            value: false,
            subValues: {
                username: false,
                phone: false,
                email: false,
                companyName: false,
                mainIdMethod: []
            },
            notes: {

            },
            type: 'checkbox'
        },
        parameters:{
            subValues:{
               options:[
                  {
                     probability:70,
                     score:20,
                     isBad:false
                  },
                  {
                     probability:30,
                     score:-10,
                     isBad:true
                  }
               ],
			   playTime: 60
            }
         }

    }
}


export default gameSettings


