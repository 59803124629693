import axios from "axios";

const host = `${window.location.hostname}`
let url = ''

if(host.includes("localhost")){
    url = `${window.location.protocol}//${host}:3000`
} else {
    url = `${process.env.REACT_APP_SERVER_URL}/`
}

const axiosInstance = axios.create({
    baseURL: url,
    headers: { "ngrok-skip-browser-warning": "true" },
    withCredentials: true,
  });
  

export default axiosInstance;