import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import nav from '../../utils/Navigation';

const LeaderboardTable = ({ data, onGameInfo , download , ...props}) => {
  // Define the columns for the DataTable
  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Score',
      selector: (row) => row.score,
      sortable: true,
    },
  ];




  return (
    <div className="table-container">
      <DataTable
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        responsive
        striped
      />
      <button type='button' onClick={() => download()}>Download Leaderboard</button>
    </div>
  );
};

export default LeaderboardTable;
