import React, { useEffect, useState } from 'react';
import MusicPlayer from '../../components/MusicPlayer/MusicPlayer';

function MusicPhase({ changePhase, handleFileChange, listOfMusicFiles , previewFiles}) {
    const [selectedTrack, setSelectedTrack] = useState('');
    const [selectedTrackName, setSelectedTrackName] = useState(null);
    const [userTracks , setUserTracks] = useState({})

    function handleUpload(e){
        const file = e.target.files[0];
        
        if (file) {
            const newFile = { name: file.name, url: URL.createObjectURL(file) };
            
            setUserTracks(prev => ({
                ...prev,
                [e.target.name]: newFile
            }))

            handleFileChange(e)
        }
       
    }

    const handleSelectTrack = (file) => {
      setSelectedTrack(`${process.env.REACT_APP_SERVER_URL}/${file}`);
      setSelectedTrackName(file.split('\\').pop());
    };

    const handleUserTrackSelect = (file) => {
        console.log(file);
        
        setSelectedTrack(file.url)
        setSelectedTrackName(file.name)
    }

    useEffect(() => {
        if (listOfMusicFiles.length > 0) {
            // Set the first music asset as the selected track when the component mounts
            setSelectedTrack(`${process.env.REACT_APP_SERVER_URL}/${listOfMusicFiles[0]}`);
        }
    }, [listOfMusicFiles]);

    useEffect(() => {
        console.log("Tracks:" , userTracks);
        
    },[userTracks])

    return (
        <div className="phases-container">
            <h2>Music Assets</h2>

            {selectedTrack && <MusicPlayer track={{src: selectedTrack }} trackName={selectedTrackName} />}

            <div className="track-selection">
                <div>
                    <label htmlFor="track-select">Default Assets:</label>
                    {listOfMusicFiles.map((file, index) => (
                    <div key={index} className="track-container">
                        <h3>{file.split('\\').pop()}</h3> {/* Display the file name */}
                        <input 
                            name={file.split('\\').pop()}
                            type="file" 
                            accept="audio/*" 
                            onChange={(e) => handleUpload(e)} 
                        />
                        <button onClick={() => handleSelectTrack(file)}>Select Track</button>
                    </div>
                ))}
                </div>
                <div>
                    <label htmlFor="track-select">User Assests</label>
                    {Object.keys(userTracks).map((file , index) => {
                        return(
                            <div>
                                <h3>{userTracks[file]?.name}</h3>
                                <button onClick={() => handleUserTrackSelect(userTracks[file])}>Select Track</button>
                            </div>
                        )
                    })}
                </div>
            </div>


            <button onClick={() => changePhase(3)}>Next</button>
        </div>
    );
}

export default MusicPhase;
