import React, { useEffect, useState } from 'react'
import DataCollector from '../../components/DataCollector/DataCollector'
import './Phases.css'
import api from '../../components/api'

function OverviewPhase({gameURL , activeGame , setActiveGame, ...props}) {
  const [latestUpdate , setLatestUpdate] = useState("None")

  async function getGameBackupDetails(){
    try {
      const res = await api.post('/get_game_latest_updates', {
        user_id: props.userDetails.id,
        game_id: props.appDetails.game_id
      })

      if(res.data.status){
        setLatestUpdate(new Date(res.data.backup[0].update_datetime).toLocaleString())
      }
      console.log(res);
      
    } catch (e) {
      console.log(e);
      
    }
  }

  useEffect(() => {
    getGameBackupDetails()
  },[])
  return (
    <div className='overview-contaienr'>
      <h2>Overview</h2>
      <div className='latest-update'>
        <h4>Latest Update:</h4>
        <h5>{latestUpdate}</h5>
      </div>
      <a href={gameURL} target='_blank'>Visit Your Game</a>
      <div>
        <DataCollector {...props} activeGame={activeGame} setActiveGame={setActiveGame} />
      </div>
    </div>
  )
}

export default OverviewPhase