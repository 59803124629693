import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import './ReferUserTable.css'; 

const ReferUserTable = ({ users }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Define the columns for the DataTable
    const columns = [
        {
            name: 'Id',
            selector: row => row.user_id,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Total Paid',
            selector: row => `$${row.total_payment_amount.toFixed(2)}`, // Format as currency
            sortable: true,
        },
        {
            name: 'Active Games',
            selector: row => row.active_games,
            sortable: true,
        },
        {
            name: 'My Commission',
            selector: row => `$${row.total_commission.toFixed(2)}`, // Format as currency
            sortable: true,
        },
    ];

    // Function to filter users based on the search term
    const filteredUsers = users.filter(user => {
        const { username } = user;
        return (
            username.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div className='user-table-container'>
            <input
                type="text"
                placeholder="Search by ID, Username"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-bar"
            />
            <DataTable
                title="Referred User List"
                columns={columns}
                data={filteredUsers}
                pagination
            />
        </div>
    );
};

export default ReferUserTable;
